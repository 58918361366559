<template>
	<v-layout>
		<Dialog :dialog="dialog" :dialogWidth="dialogWidth">
			<template v-slot:title>
				<v-layout>
					<v-flex> Manage Bank </v-flex>
					<v-flex class="text-right">
						<v-btn
							tile
							depressed
							color="blue darken-4 text-white"
							small
							class="mx-4"
							v-on:click="createBank"
						>
							<span class="font-size-14 font-weight-600"> <v-icon>mdi-plus</v-icon> New Bank</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<v-container class="py-0">
					<v-form
						ref="categoryForm"
						v-model="formValid"
						lazy-validation
						v-on:submit.stop.prevent="postNewBank"
					>
						<v-row class="py-0">
							<v-col md="12" class="py-0">
								<v-simple-table>
									<tbody>
										<template v-for="(type, index) in banksList">
											<tr :key="`category-row-${index}`" class="table-category-row">
												<td style="border: none" width="95%">
													<TextInput
														hide-details
														:disabled="pageLoading"
														placeholder="Enter Bank"
														:loading="pageLoading"
														:id="`bank-${index}`"
														v-model="type.name"
														class="mb-3"
														:class="{
															required: !type.name,
														}"
														:rules="[vrules.required(type.name, 'Bank')]"
													></TextInput>
												</td>
												<td style="border: none" width="5%">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-icon
																v-on:click="deleteBank(index, type)"
																color="deep-orange"
																v-bind="attrs"
																v-on="on"
																>mdi-delete</v-icon
															>
														</template>
														<span>Click here to delete</span>
													</v-tooltip>
												</td>
											</tr>
										</template>
									</tbody>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					:disabled="pageLoading"
					:loading="pageLoading"
					class="mx-2 custom-grey-border custom-bold-button white--text"
					color="blue darken-4 white--text"
					v-on:click="postNewBank"
				>
					Save
				</v-btn>
				<v-btn
					tile
					depressed
					:disabled="pageLoading"
					class="mx-2 custom-grey-border custom-bold-button"
					v-on:click="$emit('close-dialog', true)"
				>
					Close
				</v-btn>
			</template>
		</Dialog>
		<DeleteTemplate
			type="Bank"
			:delete-text="deleteText"
			v-on:success="removeBank"
			deleteByUrl
			v-on:close="deleteDialog = false"
			:delete-dialog="deleteDialog"
			:delete-url="deleteURL"
		>
		</DeleteTemplate>
	</v-layout>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import TextInput from "@/view/components/TextInput";
import { toSafeInteger } from "lodash";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { cloneDeep } from "lodash";
export default {
	props: {
		dialog: {
			type: Boolean,
			required: true,
			default: false,
		},
		category: {
			type: Array,
			default: () => {
				return new Array();
			},
		},
	},
	data: () => {
		return {
			formValid: true,
			pageLoading: false,
			banksList: [],
			deleteURL: null,
			deleteId: null,
			deleteDialog: false,
			deleteIndex: null,
			deleteText: null,
		};
	},
	methods: {
		postNewBank() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.categoryForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.categoryForm.validate()) {
				return false;
			}

			_this.pageLoading = true;
			ApiService.setHeader();
			ApiService.post(`bank`, { banks: _this.banksList })
				.then(({ data }) => {
					_this.$emit("success", data);
					_this.banksList = data;
					_this.$store.dispatch(SET_LOCAL_DB);
					_this.$emit("close-dialog", true);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		createBank() {
			this.banksList.push({
				name: null,
				uuid: null,
				id: null,
			});
			this.$nextTick(() => {
				const focus_element = `bank-${this.banksList.length - 1}`;
				document.getElementById(focus_element).focus();
			});
		},
		deleteBank(index, data) {
			// if (this.banksList.length > 1) {
			// 	if (data && data.id) {
			// 		this.deleteDialog = true;
			// 		this.deleteId = data.id;
			// 		this.deleteIndex = index;
			// 		this.deleteText = data.name;
			// 		this.deleteURL = "bank/" + data.id;
			// 	} else {
			// 		this.banksList.splice(index, 1);
			// 	}
			// }
			console.log(data);
			// if (this.banksList.length > 1) {
			// 	// if (data && data.id) {
			// 	// 	this.deleteDialog = true;
			// 	// 	this.deleteId = data.id;
			// 	// 	this.deleteIndex = index;
			// 	// 	this.deleteText = data.name;
			// 	// 	this.deleteURL = "bank/" + data.id;
			// 	// } else {
			// 	this.banksList.splice(index, 1);
			// 	// }
			// }
			this.banksList.splice(index, 1);
		},
		removeBank() {
			if (this.deleteIndex) {
				this.banksList.splice(this.deleteIndex, 1);
				this.deleteIndex = null;
				this.deleteDialog = false;
				this.$store.dispatch(SET_LOCAL_DB);
				this.$emit("success", this.banksList);
			}
		},
	},
	components: {
		Dialog,
		DeleteTemplate,
		TextInput,
	},
	mounted() {
		this.banksList = cloneDeep(this.localDB("banks", []));
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			if (document.body.clientWidth > 992) {
				return 800;
			}
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
